// @flow
import React from 'react';
import styled from 'styled-components';
import {
  Carousel,
  GlossaryLink,
  GlossaryQuote,
  Image,
  Layout,
  Link,
  withFrontMatter,
} from '../components';
import type { FrontMatter, ImageType } from '../utils/types';
import { graphql } from 'gatsby';
import route from '../routes';
import allLinks from '../data/all-links';

const SmallImage = styled(Image)`
  width: 100%;
  max-width: 500px;
`;

const SmallCarousel = styled(Carousel)`
  width: 100%;
  max-width: 500px;
  align-self: center;
`;

type Props = {
  frontMatter: FrontMatter,
  data: {
    opengraph: ImageType,
    oval: ImageType,
    d: ImageType,
    hms: ImageType,
    gate: ImageType,
    nose: ImageType,
    skinnyEnd: ImageType,
    spine: ImageType,
    workingEnd: ImageType,
    major: ImageType,
    minor: ImageType,
    mbsKn: ImageType,
    mbsMajor: ImageType,
    mbsMinor: ImageType,
    mbsOpen: ImageType,
    mbsGate: ImageType,
    mbsCe: ImageType,
    noseHook: ImageType,
    noseKeylock: ImageType,
    gateAngle1: ImageType,
    gateAngle2: ImageType,
    gateAngle3: ImageType,
    convex: ImageType,
    climbingVersusCanyoneering: ImageType,
  },
};

class Canyoneering_Carabiner extends React.Component<Props> {
  render() {
    const { frontMatter, data } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <Image image={data.opengraph} />
        <GlossaryQuote>carabiner</GlossaryQuote>
        <h2>Parts of a Carabiner</h2>
        <p>
          Some of the parts here are specific to HMS carabiners and I am using
          names I have heard in canyoneering.
        </p>
        <SmallCarousel
          images={[
            data.workingEnd,
            data.spine,
            data.skinnyEnd,
            data.gate,
            data.nose,
            data.major,
            data.minor,
          ]}
          captions={[
            <>
              Working End
              <br />
              <Link to="https://www.petzl.com/US/en/Sport/Carabiners-and-quickdraws/WILLIAM">
                Petzl William
              </Link>
            </>,
            <>
              Spine
              <br />
              <Link to="https://www.petzl.com/US/en/Sport/Carabiners-and-quickdraws/WILLIAM">
                Petzl William
              </Link>
            </>,
            <>
              Skinny End
              <br />
              <Link to="https://www.petzl.com/US/en/Sport/Carabiners-and-quickdraws/WILLIAM">
                Petzl William
              </Link>
            </>,
            <>
              Gate
              <br />
              <Link to="https://www.petzl.com/US/en/Sport/Carabiners-and-quickdraws/WILLIAM">
                Petzl William
              </Link>
            </>,
            <>
              Nose
              <br />
              <Link to="https://www.petzl.com/US/en/Sport/Carabiners-and-quickdraws/WILLIAM">
                Petzl William
              </Link>
            </>,
            <>
              Major Axis
              <br />
              <Link to="https://www.petzl.com/US/en/Sport/Carabiners-and-quickdraws/WILLIAM">
                Petzl William
              </Link>
            </>,
            <>
              Minor Axis
              <br />
              <Link to="https://www.petzl.com/US/en/Sport/Carabiners-and-quickdraws/WILLIAM">
                Petzl William
              </Link>
            </>,
          ]}
        />
        <h3>Working End</h3>
        <p>
          The versatile end that holds the devices that do things - devices such
          as <GlossaryLink>ascenders</GlossaryLink>,{' '}
          <GlossaryLink>rappel devices</GlossaryLink>,{' '}
          <GlossaryLink id="friction-hitch">friction hitches</GlossaryLink>,{' '}
          <GlossaryLink>safety tethers</GlossaryLink>, or a{' '}
          <GlossaryLink>munter hitch</GlossaryLink>. I've also heard it called
          Basket and Wide End.
        </p>
        <h3>Spine</h3>
        <p>
          This is the weight-bearing part of the carabiner. It is also where one
          ties a hitch onto the carabiner for a{' '}
          <GlossaryLink>biner block</GlossaryLink> or bights on a{' '}
          <GlossaryLink>Jester</GlossaryLink> or{' '}
          <GlossaryLink>plaquette</GlossaryLink>.
        </p>
        <h3>Skinny End</h3>
        <p>
          The end that attaches to a single point such as the anchor or a
          rappeller. Also called the Runner End.
        </p>
        <h3>Gate</h3>
        <p>The opening part of the carabiner beyond the hinge.</p>
        <h3>Nose</h3>
        <p>The tip of the carabiner that touches the gate when closed.</p>
        <h3>Major Axis</h3>
        <p>The direction a carabiner is designed to be weighted.</p>
        <h3>Minor Axis</h3>
        <p>The direction that cross-loads a carabiner.</p>

        <h2>Types of Carabiners</h2>
        <p>
          There are actually many types of carabiners for with specialized uses,
          but I'll just go over three climbing-specific types.
        </p>
        <h3>Oval</h3>
        <SmallImage
          image={data.oval}
          caption="Oval carabiner - Omega Pacific Oval"
        />
        <p>
          The original carabiner shape. This carabiner balances the load on both
          the strong spine and the weak gate which results in a somewhat heavier
          carabiner to obtain adequate strength. However, the wide basket and
          symmetrical shape provide some advantages.
        </p>
        <p>Advantages</p>
        <ul>
          <li>Symmetrical shape limits load shifting.</li>
          <li>Works with wide devices such as pulleys, ascenders, etc.</li>
        </ul>
        <p>Disadvantages</p>
        <ul>
          <li>Heavier to account for inherently week shape.</li>
          <li>Smaller gate opening than other shapes.</li>
        </ul>
        <h3>D Shape</h3>
        <SmallImage
          image={data.d}
          caption={
            <>
              D carabiner -{' '}
              <Link to="https://www.rockexotica.com/product/steel-carabiners/rockd-stainless/">
                Rock Exotica rockD Steel
              </Link>
            </>
          }
        />
        <p>
          This shape attemps to remove the principle disadvantage of the Oval
          shape by positioning the load onto the spine of the carabiner. This
          results in the lightest shaped carabiner but also makes it the least
          versatile.
        </p>
        <p>Advantages</p>
        <ul>
          <li>Strongest shape results in lightest weight for a carabiner.</li>
          <li>Works great connecting rope and webbing.</li>
        </ul>
        <p>Disadvantages</p>
        <ul>
          <li>Unable to handle wide devices.</li>
          <li>Unable to handle multiple connections.</li>
        </ul>
        <h3>HMS (pear) Shape</h3>
        <SmallImage
          image={data.hms}
          caption={
            <>
              HMS carabiner -{' '}
              <Link to="https://www.petzl.com/US/en/Sport/Carabiners-and-quickdraws/ATTACHE">
                Petzl Attache
              </Link>
            </>
          }
        />
        <p>
          The HMS carabiner was designed for the{' '}
          <GlossaryLink>Munter Hitch</GlossaryLink> and is inherently the most
          versatile carabiner and the preferred shape of most canyoneering
          carabiners. Much more on this shape in the rest of the article.
        </p>
        <p>Advantages</p>
        <ul>
          <li>Wide working end allows multiple attachments.</li>
          <li>Wide gate opening.</li>
          <li>
            Can be used for almost all canyoneering functions. More on this
            below.
          </li>
        </ul>
        <p>Disadvantages</p>
        <ul>
          <li>Heavier than D carabiners.</li>
          <li>
            Heavest load must be placed on spine side. More on this below.
          </li>
        </ul>

        <h2>Strength and Certification</h2>
        <p>
          Carabiners must be strong enough to withstand the extreme forces
          placed upon it by canyoneers. If it doesn't have a valid climbing
          certification it should not be used. All carabiners I use have a{' '}
          <GlossaryLink>CE marking</GlossaryLink> and show the{' '}
          <GlossaryLink>minimum breaking strength</GlossaryLink> on the major
          axis, minor axis, and with an open gate. If the carabiner says SWL
          (Safe Working Load) or "NOT FOR CLIMBING" then it is not certified for
          climbing and should not be used. See{' '}
          <Link to="https://blog.weighmyrack.com/ensure-your-carabiner-is-strong-enough-to-climb-on/">
            this excellent blog post from Weigh My Rack
          </Link>{' '}
          for more information on carabiner strength.
        </p>
        <Carousel
          images={[
            data.mbsKn,
            data.mbsMajor,
            data.mbsMinor,
            data.mbsOpen,
            data.mbsGate,
            data.mbsCe,
          ]}
          captions={[
            <>
              kN Marking - Denotes that the ratings are in{' '}
              <GlossaryLink>kilonewtons</GlossaryLink>
            </>,
            'Major Axis - 26 kN - The carabiner is pulled in its designed direction',
            'Minor Axis - 11 kN - The carabiner is cross-loaded',
            'Open Gate - 7 kN - The carabiner is pulled on its major axis with the gate opened',
            <>
              Gate Squeeze - 6 kN - I <em>think</em> it is for squeezing the
              gate while locked. I also don't think this is required to be on
              the carabiner.
            </>,
            <>
              <GlossaryLink>CE Marking</GlossaryLink> - the carabiner's
              certification body.
            </>,
          ]}
        />
        <GlossaryQuote>Minimum Breaking Strength</GlossaryQuote>
        <p>
          Carabiners used for canyoneering have a minimum breaking strength
          rating printed or forged somewhere on the carabiner for at least the
          major axis, minor axis, and with an open gate.
        </p>

        <h2>Carabiners for Canyoneering</h2>
        <p>
          The above definition entails many types that do not work for
          canyoneering. Let's briefly talk about these types and why they should
          not be used.
        </p>
        <h3>Non-Locking Gates</h3>
        <p>
          Carabiners with non-locking gates are are typically used as part of a
          climbing quickdraw. Their purpose is to allow a climber to quickly
          clip into the rope with one hand and continue up a pitch. There is no
          practical application for a non-locking carabiner in canyoneering and
          these types of carabiners should be left at home.
        </p>
        <p>
          The one exception to this is using two non-locking carabiners in place
          of a single locking carabiner. The carabiners must be oriented
          opposite of each other so that when the gates open it forms an X.
        </p>

        <h3>Auto Locking Gates</h3>
        <p>
          Auto locking gates require that the internal mechanisms be clean to
          operate properly. Since canyoneering is a dirty, nasty, filthy affair
          and carabiners often get dragged through dirt and mud, auto lockers
          generally don't have a place in canyoneering, either.
        </p>
        <p>
          Other problems with auto lockers are that they are generally more
          expensive than the screw gate versions. They are also often a little
          heavier. They are more fragile - their locking mechanisms can break
          more easily if dropped. Finally, the locking mechanisms aren't
          necessarily intuitive. You can't hand one to another canyoneer and
          expect them to know how to use it. Passing a screw gate is always a
          better idea.
        </p>
        <p>
          The one place I still see auto lockers used is the carabiner attached
          to a canyoneer's <GlossaryLink>rappel device</GlossaryLink>. Since
          these carabiners will never be used for{' '}
          <GlossaryLink>rigging</GlossaryLink> or in a{' '}
          <GlossaryLink>biner block</GlossaryLink>, they won't necessarily get
          dirty every time they are used. I personally do not subscribe to this
          method but as long as the canyoneer who uses auto lockers is aware
          that they need to keep it clean then I don't care.
        </p>

        <h3>Material - Aluminum vs Steel vs Hybrid</h3>
        <p>
          <b>Aluminum</b> is significantly lighter than a similar steel
          carabiner. For example, the very-light-for-being-steel carabiner I use
          weighs 190 grams and the aluminum carabiners I bring with me weigh 80
          grams. That means I could carry two aluminum carabiners and still
          bring less weight than a single steel biner.
        </p>
        <p>
          The principle advantage of <b>steel</b> carabiners for canyoneering
          are that they are much more durable than aluminum carabiners. I have
          seen aluminum carabiners wear out in just one canyon. Before getting
          my steel carabiner I had to inspect my aluminum wear points after
          every rappel in some canyons. The steel one should last me years.
        </p>
        <p>
          Other less important differences are that aluminum carabiners are
          generally less expensive and steel carabiners have a much greater
          minimum breaking strength.
        </p>
        <p>
          I also use a <b>hybrid</b> steel/aluminum that is mostly aluminum with
          a steel plate on the basket. This has the light weight advantage of
          aluminum with the durability of steel at its wear point and costs
          about as much as an aluminum and steel carabiner put together.
        </p>
        <p>
          When the hybrid carabiner was first introduced some canyoneers
          expressed concern that once the steel wears away in the middle it
          would become sharp and could instantly cut the rope. I'll let you know
          if this ever happens.
        </p>

        <h3>Beam Shape</h3>
        <SmallCarousel
          images={[data.convex, data.hms]}
          captions={[
            <>
              Round stock carabiner (convex) - note that the carabiner shaft
              looks completely rounded
              <br />
              <Link to="https://www.rockexotica.com/pirate/">
                Rock Exotica Pirate
              </Link>
            </>,
            <>
              H beam carabiner (concave) - note that the middle of the shaft is
              much skinnier than the inside and outside edges. This gives
              strength to the areas that need it while removing unnecessary
              bulk.
              <br />
              <Link to="https://www.petzl.com/US/en/Sport/Carabiners-and-quickdraws/ATTACHE">
                Petzl Attache
              </Link>
            </>,
          ]}
        />
        <p>
          Beam shape is the term I use to describe the cross-section of the
          carabiner shaft. The original carabiners were all round or convex.
          However, much of the aluminum material doesn't actually add strength
          to the carabiner and is basically just useless additional weight. I
          call carabiners that are shaped in a way that do not include this
          additional material concave. I've also heard it referred to as I Beam
          or H Beam but there are many other variations.
        </p>
        <p>
          As stated above, concave carabiners weight less than their convex
          counterparts. That's their one advantage. They are also slowly phasing
          out many of the older convex shapes in higher-end carabiners.
        </p>
        <p>
          The principle advantage of convex carabiners are that they wear out
          more evenly and provide more consistent friction when on rappel. They
          are also cheaper to manufacturer and that savings is passed onto the
          purchaser
        </p>

        <h3>Nose Type</h3>
        <SmallCarousel
          images={[data.noseHook, data.noseKeylock]}
          captions={[
            'Hook nose type - gets caught all the time',
            "Keylock nose type - doesn't get caught",
          ]}
        />
        <p>
          The original nose type looks like a hook where the gate rests. These
          hooks tend to catch on gear when pulling it off of the carabiner.
        </p>
        <p>
          Most new carabiners feature a "keylock" nose that eliminates the need
          for a hook by having the gate pinch the nose instead of hook into it.
          This nose is apparently a little more expensive to manufacture.
        </p>

        <h3>Gate Angle</h3>
        <SmallCarousel
          images={[data.gateAngle1, data.gateAngle2, data.gateAngle3]}
          captions={[
            'Angled Gate for larger gate opening - Note that gate is behind the spine',
            'Angled Gate for larger gate opening - Note that gate is in front of spine',
            'Angled Gate for larger gate opening - Yet another view',
          ]}
        />
        <p>
          A few carabiner gates don't open inline with the rest of the
          carabiner. The open at a slight angle that allows the gate to open
          slightly more before touching the spine.
        </p>
        <p>
          <Link to={route.blog_2018_04_15_one_hundred_canyons_later}>
            I have a harness
          </Link>{' '}
          that I used for years. When I first got the harness I discovered that
          my Petzl Attaches couldn't clip into the gear loops very easily. I
          ended up switching from Attaches to Rock Exotica Pirates. Even though
          the carabiners where nearly the same size, the Pirates had an angled
          gate opening that allowed them to open just a bit more and easily clip
          into the wide gear loops.
        </p>

        <h3>Carabiner Size</h3>
        <p>
          In general, larger carabiners are more useful than small carabiners.
          Advantages of each as follows.
        </p>
        <p>Small carabiners are a little lighter and less expensive.</p>
        <p>
          Large carabiners won't fit through the largest{' '}
          <GlossaryLink>rapides</GlossaryLink> like small carabiners can. They
          are also much easier to connect things to since they have a wider
          opening. I have struggled with getting{' '}
          <GlossaryLink id="block-hitch">block hitches</GlossaryLink> off of
          small carabiners because of the small gate opening and small area to
          "break" the tightened hitch. I definitely have a bias toward larger
          carabiners.
        </p>

        <h3>Carabiner Shape</h3>
        <p>
          The preferred carabiner shape for canyoneering is the HMS carabiner
          shape due to its versatility. There are very few things an HMS
          carabiner cannot do that other carabiners can do better.
        </p>
        <p>
          The one big exception to this is the carabiner used for a Tibloc. It
          should use an Oval carabiner. Petzl says the following in regards to
          the Tibloc:
        </p>
        <blockquote>
          <Link to="https://www.petzl.com/US/en/Sport/FAQ/can-i-use-a-tibloc-with-an-attache-3d-carabiner">
            Can I use a TIBLOC with an ATTACHE 3D carabiner?
          </Link>
          <br />
          Carabiner shape and cross-section play a significant role in the
          jamming of the rope. For optimal operation, a carabiner with round
          cross section is preferable.
        </blockquote>

        <h2>TL;DR The Best Carabiner for Canyoneering</h2>
        <p>
          If I were to buy only one type of carabiner for canyoneering it would
          be large, aluminum, HMS, screw gate, key-lock, with a convex beam.
          This is the type of carabiner I buy most of the time. There are places
          where other carabiners work better, but this type of carabiner works
          well enough in every canyoneering application I have experienced so
          far.
        </p>
        <p>
          As a matter of fact, I used this type of carabiner exclusively in
          canyons for years without ever being questioned on it or giving it a
          second thought.
        </p>
        <p>
          If I were to buy 2 types of carabiners I'd get a steel or hybrid
          carabiner for my rappel device. Or some larger carabiners like the
          Petzl William or Sterling Eagle. Or some smaller ones that I don't cry
          about when I loan them out and they never get returned. I don't know.
        </p>

        <h3>Thoughts on some carabiners</h3>
        <ul>
          <li>
            <Link to="https://www.metoliusclimbing.com/element_belay_carabiner.html">
              Metolius Element
            </Link>{' '}
            - my first HMS carabiners. Too small for biner blocks and thicker
            gear loops. Solid carabiner otherwise. I have two attached to{' '}
            <GlossaryLink>etriers</GlossaryLink> and{' '}
            <GlossaryLink>hooks</GlossaryLink>.
          </li>
          <li>
            <Link to="https://www.blackdiamondequipment.com/en_US/climbing-carabiners-quickdraws/rocklock-screwgate-BD2102750000ALL1.html">
              Black Diamond Rocklock
            </Link>{' '}
            - I have never really used them and don't own any. I know a lot of
            good canyoneers who like them, though.
          </li>
          <li>
            <Link to="https://smcgear.com/smc-jake.html">
              Omega Pacific Jake Keylock
            </Link>{' '}
            - I have the hook-nose version and get annoyed that it gets caught
            on stuff all the time. I also don't like that it's got a concave
            beam but it hasn't mattered so far. I love how massive it is.
          </li>
          <li>
            Petzl Attache - the old convex beam ones were fine but a tad too
            small for my Petzl harness gear loops. Not a fan of the new ones
            with concave beams. Many people believe and I agree that they're not
            good for canyoneering. Intentionally not adding a link.
          </li>
          <li>
            Petzl William - the old convex beam ones were great - big, light,
            versatile. The new concave beam ones are not good for canyoneering.
            Intentionally not adding a link.
          </li>
          <li>
            <Link to="https://www.rockexotica.com/product/aluminum-carabiners/pirate/">
              Rock Exotica Pirate
            </Link>{' '}
            - one of my favorite carabiners. I don't like the screw gate - you
            have to spin it one or two additional times to get it to lock versus
            the Petzl screw gate. That's my only complaint. If you buy them in
            bulk you can get them custom laser etched.
          </li>
          <li>
            <Link to="https://sterlingrope.com/store/work/hardware/carabiners-snaphooks/falcon">
              Sterling Falcon
            </Link>{' '}
            - my personal favorite carabiner. looks unusually similar to the
            Rock Exotica Pirate but with the superior Petzl screw gate. It has
            an inline gate opening which is inferior to the Pirate but according
            to the manufacturers they have the same gate opening size of 1 inch.
            I haven't noticed a difference and I like the screw gate better.
          </li>
          <li>
            <Link to="https://sterlingrope.com/store/work/hardware/carabiners-snaphooks/eagle">
              Sterling Eagle
            </Link>{' '}
            - looks unusually similar to the old Petzl William but slightly
            thicker. Big and therefore versatile. People think I'm cool when
            they see them.
          </li>
        </ul>
        <Image
          image={data.climbingVersusCanyoneering}
          caption="One of these carabiners has been on my harness"
        />
        <h2>Maintenance</h2>
        <h3>Marking</h3>
        <p>
          There is no way to mark carabiners and have the marking last. Lots of
          people use tape on various locations. Lots of people, myself included,
          use nail polish. I chose to put nail polish near the gate hinge
          because that seemed like the place that would last the longest. I have
          found that if I mark them once a year it works well enough. You can
          see the "before" and "after one year" in the above image.
        </p>
        <p>
          <Link to="https://www.petzl.com/US/en/Sport/FAQ/is-it-possible-to-engrave-a-carabiner-and-how-can-i-identify-a-metallic-product-when-its-serial-number-is-no-longer-decipherable">
            Some information on marking from Petzl
          </Link>
          .
        </p>
        <h3>Maintenance</h3>
        <p>
          Wash carabiners with water and optionally mild soap. I rinse my
          carabiners under the faucet and have never used soap. If sand gets in
          the screw gate I screw and unscrew it while under the faucet and the
          sand works itself out.
        </p>
        <p>
          You can lubricate the spring with oil. I have heard you can use
          graphite to lubricate the screw but have never tried it or confirmed
          it with any manufacturer. Don't use WD 40.
        </p>
        <p>
          <Link to={allLinks.carabinerCare}>
            Some information on maintenance from Petzl
          </Link>
          .
        </p>
        <h3>End of Life</h3>
        <p>
          Unlike plastic products, there is no end-of-life for metal carabiners.
          However, if at any point you do not feel 100% confident in the
          carabiner then it should be retired. The most common reason for me to
          retire a carabiner is when the working end gets worn out from
          rappelling.
        </p>
        <p>
          I occasionally find previous party's carabiners while canyoneering and
          never use them because I do not feel 100% confident and am not
          familiar with where it has been.
        </p>
        <p>
          If you drop a carabiner does it create micro-fractures in the aluminum
          and you have to retire it? I'm not an authority on any of this. This
          is all just medium quality canyoneering information. Doing some quick
          searches leads me to believe it came from one bad run of carabiners
          where there were visible cracks from drilling the hinge pinholes. This
          happened way back before any of us were born but the urban legend has
          perpetuated this long.
        </p>
      </Layout>
    );
  }
}

export default withFrontMatter('carabiner')(Canyoneering_Carabiner);

export const query = graphql`
  query {
    opengraph: file(relativePath: { eq: "images/opengraph/carabiner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hms: file(relativePath: { eq: "images/canyoneering/carabiner/hms.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    oval: file(relativePath: { eq: "images/canyoneering/carabiner/o.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    d: file(relativePath: { eq: "images/canyoneering/carabiner/d.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    gate: file(relativePath: { eq: "images/canyoneering/carabiner/gate.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    skinnyEnd: file(
      relativePath: { eq: "images/canyoneering/carabiner/skinny-end.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    spine: file(
      relativePath: { eq: "images/canyoneering/carabiner/spine.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    workingEnd: file(
      relativePath: { eq: "images/canyoneering/carabiner/working-end.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    nose: file(relativePath: { eq: "images/canyoneering/carabiner/nose.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    major: file(
      relativePath: { eq: "images/canyoneering/carabiner/major.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    minor: file(
      relativePath: { eq: "images/canyoneering/carabiner/minor.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    mbsKn: file(
      relativePath: { eq: "images/canyoneering/carabiner/mbs-kn.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    mbsMajor: file(
      relativePath: { eq: "images/canyoneering/carabiner/mbs-major.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    mbsMinor: file(
      relativePath: { eq: "images/canyoneering/carabiner/mbs-minor.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    mbsOpen: file(
      relativePath: { eq: "images/canyoneering/carabiner/mbs-open.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    mbsGate: file(
      relativePath: { eq: "images/canyoneering/carabiner/mbs-gate.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    mbsCe: file(
      relativePath: { eq: "images/canyoneering/carabiner/mbs-ce.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    convex: file(
      relativePath: { eq: "images/canyoneering/carabiner/convex.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    noseHook: file(
      relativePath: { eq: "images/canyoneering/carabiner/nose-hook.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    noseKeylock: file(
      relativePath: { eq: "images/canyoneering/carabiner/nose-keylock.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    gateAngle1: file(
      relativePath: { eq: "images/canyoneering/carabiner/gate-angle-1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    gateAngle2: file(
      relativePath: { eq: "images/canyoneering/carabiner/gate-angle-2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    gateAngle3: file(
      relativePath: { eq: "images/canyoneering/carabiner/gate-angle-3.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    climbingVersusCanyoneering: file(
      relativePath: {
        eq: "images/canyoneering/carabiner/climbing-versus-canyoneering.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
