// @flow
const allLinks = Object.freeze({
  acaSkillsChecklists_20180925:
    'https://drive.google.com/file/d/1f4KDnqxN0CjltJXcmqgPLEET2eKG8C6V/view?usp=sharing',
  skillsChecklistTxt:
    'https://drive.google.com/drive/folders/1uI_U4ZfdZObT2TmkIL40OKs5CZd8S62l?usp=sharing',
  carabinerCare:
    'https://drive.google.com/file/d/1fMUJdnRnNMvgbsAA2mp_PGD2KOEhrR8I/view?usp=sharing',
});

export default allLinks;
